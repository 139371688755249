import { Container, Tab, Tabs } from "react-bootstrap";
import PDFViewer from "../components/PDFViewer";
import RegulamentoSorteioPDF from '../assets/docs/regulamento-sorteio.pdf';
import RegulamentoCompreEGanhePDF from '../assets/docs/regulamento-compre-e-ganhe.pdf';
import { Suspense } from "react";

export function Component() {
  return (
    <Suspense fallback={<h1>Carregando...</h1>}>
      <section>
        <Container className="mb-5">
          <h1 className="text-center">REGULAMENTO</h1>

          <Tabs itemType="pill" defaultActiveKey="tab1" id="regulation-tabs">
            <Tab eventKey="tab1" title="SORTEIO">
              <a className="d-inline-block my-4 me-1" href={RegulamentoSorteioPDF} download>Clique aqui</a>
              <span>para baixar o regulamento</span>
              <PDFViewer url={RegulamentoSorteioPDF} />
            </Tab>
            <Tab eventKey="tab2" title="VALE-BRINDE">
            <a className="d-inline-block my-4 me-1" href={RegulamentoCompreEGanhePDF} download>Clique aqui</a>
              <span>para baixar o regulamento</span>
              <PDFViewer url={RegulamentoCompreEGanhePDF} />
            </Tab>
          </Tabs>
        </Container>
      </section>
    </Suspense>
  );
}
