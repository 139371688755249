import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const PDFViewer = ({ url }: { url: string }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "80vh",
      }}
    >
      <Viewer fileUrl={url} />
    </div>
  );
};

export default PDFViewer;
